export function decompressCallData(compressedHexString: string) {
  let decompressedData = [] as number[];
  let length = compressedHexString.length;

  for (let i = 0; i < length; i += 2) {
      let byteStr = compressedHexString.substring(i, i + 2);
      if (byteStr === "00") {
          if (i + 2 < length) {
              let zeroCount = parseInt(compressedHexString.substring(i + 2, i + 4), 16);
              for (let j = 0; j < zeroCount; j++) {
                  decompressedData.push(0);
              }
              i += 2;
          }
      } else {
          decompressedData.push(parseInt(byteStr, 16));
      }
  }

  let result = '';
  for (let b of decompressedData) {
      result += b.toString(16).padStart(2, '0');
  }

  return '0x' + result;
}