import { Box, BoxProps, Modal, ModalProps, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { error, white } from "../color";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const MyModal: React.FC<{ boxProps: BoxProps; modalProps: ModalProps }> = (
  props
) => {
  const { children, ...restBoxProps } = props.boxProps;
  return (
    <Modal
      {...props.modalProps}
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <Box
        display={"inline-flex"}
        flexDirection={"row"}
        component={"header"}
        alignItems={"center"}
        height={"300px"}
        maxWidth={"400px"}
        width={"400px"}
        paddingX={3}
        marginBottom={5}
        borderRadius={"8px"}
        bgcolor={"#292C33"}
        position={"relative"}
        {...restBoxProps}
      >
        <CloseIcon
          sx={{
            position: "absolute",
            right: 12,
            top: 12,
            color: white,
            zIndex: 2,
            cursor: "pointer",
          }}
          component={"svg"}
          onClick={() =>
            props.modalProps.onClose &&
            props.modalProps.onClose({}, "backdropClick")
          }
        />
        {children}
      </Box>
      {/* </Box> */}
    </Modal>
  );
};

export const ErrorModal: React.FC<{
  errorText: string;
  open: boolean;
  onClose: () => void;
}> = ({ errorText, open, onClose }) => {
  return (
    <MyModal
      boxProps={{
        children: (
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <ErrorOutlineIcon
              sx={{
                fontSize: "100px",
                color: error,
                marginTop: 8,
              }}
            ></ErrorOutlineIcon>
            <Typography marginTop={4} color={error}>
              {errorText}
            </Typography>
          </Box>
        ),
      }}
      modalProps={{
        open: open,
        children: <></>,
        onClose() {
          onClose();
        },
      }}
    />
  );
};

export default MyModal;
