import { SvgIcon, SvgIconProps } from "@mui/material"


export default (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.875C18.0061 22.875 22.875 18.0061 22.875 12C22.875 5.9939 18.0061 1.125 12 1.125C5.9939 1.125 1.125 5.9939 1.125 12C1.125 18.0061 5.9939 22.875 12 22.875ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" />
      <path d="M16.9718 9.01566L10.2101 15.7774L7.02814 12.5954L7.82126 11.8023L10.2101 14.1855L16.1787 8.22253L16.9718 9.01566Z" />
    </SvgIcon>
  )
}
{/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.875C18.0061 22.875 22.875 18.0061 22.875 12C22.875 5.9939 18.0061 1.125 12 1.125C5.9939 1.125 1.125 5.9939 1.125 12C1.125 18.0061 5.9939 22.875 12 22.875ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#00BBA8"/>
<path d="M16.9718 9.01566L10.2101 15.7774L7.02814 12.5954L7.82126 11.8023L10.2101 14.1855L16.1787 8.22253L16.9718 9.01566Z" fill="#00BBA8"/>
</svg> */}
