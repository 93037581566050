import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { envConfig } from "../constants";

export const web3Modal = createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata: {
      name: "loopring smart wallet helper",
      description: "loopring smart wallet helper",
      url: "https://claimtaiko.loopring.io",
      icons: ["https://static.loopring.io/assets/svg/logo.svg"],
    },
  }),
  themeMode: "dark",
  chains: [
    {
      chainId: 1,
      name: "Ethereum",
      currency: "ETH",
      explorerUrl: "https://etherscan.io",
      rpcUrl: "https://mainneteth.loopring.io",
    },
  ].concat(
    process.env.REACT_APP_SHOW_TESTNET === 'true'
      ? {
          chainId: 11155111,
          name: "Sepolia",
          currency: "ETH",
          explorerUrl: "https://sepolia.etherscan.io",
          rpcUrl:
            "https://sepolia.infura.io/v3/b951a403f3d3426fb2008c6923254dbc",
        }
      : []
  ),
  projectId: "d83e5adc51cd2209235e540cd303afd0",
  enableAnalytics: true, // Optional - defaults to your Cloud configuration,
  featuredWalletIds: [],
});
