import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { blueButtonBg, buttonDisabledBg, buttonDisabledColor, white } from "../color";

interface MyButtonProps extends ButtonProps {
  text?: string;
  backgroundColor?: string;
  textColor?: string;
}

const MyButton: React.FC<MyButtonProps> = ({ sx, ...props }) => {
  return (
    <Button
      variant="contained"
      sx={{
        bgcolor: blueButtonBg,
        color: white,
        textTransform: "none",
        fontSize: '20px',
        fontWeight: 500,
        ':hover': {
          bgcolor: blueButtonBg,
          color: white,
        },
        ':disabled': {
          bgcolor: buttonDisabledBg,
          color: buttonDisabledColor,
        },
        ...sx,
      }}
      {...props}
    />
  );
};

export default MyButton;
