import { useState } from "react";
import "./App.css";
import {
  Box,
  Button,
  CircularProgress,
  Input,
  Typography,
} from "@mui/material";
import "./utils/web3Modal";
import "./utils/api";
import { checkIsError, sendUserOp, signTransferToken } from "./utils/userop";
import { BigNumber, constants, providers, utils } from "ethers";
import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
  useWeb3ModalTheme,
} from "@web3modal/ethers5/react";
import { UserOperation } from "./types";
import { envConfig } from "./constants";
import { readFileQrCode } from "./utils/readFileQrCode";
import { decompressCallData } from "./utils/compress";
import { darkBg, darkBg2, green, text2, white } from "./color";
import MyButton from "./components/MyButton";
import CropFreeIcon from "@mui/icons-material/CropFree";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoneIcon from "./components/Icons/DoneIcon";
import { isHexString } from "ethers/lib/utils";
import { EXPLORER_MAP } from "./config";
import { hashShort } from "./utils/address";
import MyModal, { ErrorModal } from "./components/MyModal";

const Header = () => {
  return (
    <Box
      maxWidth={"1152px"}
      width={"95%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      height={"64px"}
    >
      <Box
        component={"img"}
        src={"https://static.loopring.io/assets/svg/logo.svg"}
      />
      <Box>
        <w3m-button />
      </Box>
    </Box>
  );
};

const Content = ({ showError }: { showError: (t: string) => void }) => {
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const [state, setState] = useState<
    | {
        // txHash?: string;
        input: string;
        status: "inputting";
      }
    | {
        input: string;
        from: string;
        to: string;
        data: string;
        value?: string;
        status: "confirming";
      }
    | {
        from: string;
        to: string;
        value?: string;
        data: string;
        status: "sent";
        hash: string;
        fee?: string;
      }
  >(
    //   {
    //   input: "",
    //   status: "inputting",
    // }
    {
      // from: "from",
      // to: "to",
      // data: "data",
      status: "inputting",
      input: "",
      // fee: '0.1'
    }
  );
  const { walletProvider } = useWeb3ModalProvider();
  const { open } = useWeb3Modal();
  return isConnected ? (
    state.status === "inputting" ? (
      <Box
        padding={5}
        bgcolor={darkBg2}
        marginTop={7}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"800px"}
        max-width={"95%"}
        overflow={"scroll"}
        maxHeight={"80vh"}
        height={"500px"}
      >
        <Typography color={white} fontSize={"24px"}>
          Transaction
        </Typography>
        <Typography color={"#A4ABC1"} marginTop={2.5} fontSize={"14px"}>
          Please upload the Loopring transaction QR code or enter the TX Code.
        </Typography>
        <Typography
          alignSelf={"self-start"}
          color={"#A4ABC1"}
          marginTop={5}
          fontSize={"14px"}
        >
          Upload QR Code or Enter Tx Code
        </Typography>
        <Input
          onChange={(e) => setState({ ...state, input: e.target.value })}
          sx={{
            marginTop: 1,
            height: "48px",
            border: "1px solid #4A505C",
            borderRadius: "4px",
            bgcolor: "#31353D",
            paddingX: 1,
            color: white,
          }}
          disableUnderline
          fullWidth
          value={state.input}
          endAdornment={
            <CropFreeIcon
              sx={{ cursor: "pointer", marginLeft: 0.5 }}
              component={"svg"}
              onClick={async () => {
                if (!walletProvider) {
                  showError("wallet not connected");
                  return;
                }
                const pro = new providers.Web3Provider(walletProvider!);
                // const signer = pro.getSigner();
                //  const network = envConfig.network; // todo

                const fileStr = await readFileQrCode();
                if (!fileStr) {
                  showError("read qrcode error");
                  return;
                }
                const _json = JSON.parse(fileStr);

                if (!_json.to) {
                  showError("qrcode not contain filed `to`");
                  return;
                }
                if (!_json.data) {
                  showError("qrcode not contain filed `data`");
                  return;
                }
                setState({
                  ...state,
                  input: fileStr,
                });
              }}
            />
          }
        />
        <MyButton
          sx={{ marginTop: 15.5 }}
          fullWidth
          disabled={!state.input}
          onClick={async () => {
            if (!walletProvider) {
              showError("wallet not connected");
              return;
            }
            const pro = new providers.Web3Provider(walletProvider!);
            const signer = pro.getSigner();
            //  const network = envConfig.network; // todo

            const fileStr = state.input;
            if (!fileStr) {
              showError("No input");
              return;
            }
            const _json = JSON.parse(fileStr);
            if (!_json.to) {
              showError("JSON does not contain the field 'to'.");
              return;
            }
            if (!_json.data) {
              showError("JSON does not contain the field 'data'.");
              return;
            }

            setState({
              status: "confirming",
              from: address!,
              data: _json.data,
              to: _json.to,
              value: _json.value,
              input: state.input,
            });
          }}
        >
          Confirm
        </MyButton>
      </Box>
    ) : state.status === "sent" ? (
      <Box
        padding={5}
        bgcolor={darkBg2}
        marginTop={7}
        display={"flex"}
        flexDirection={"column"}
        width={"800px"}
        height={"500px"}
        overflow={"scroll"}
      >
        <ArrowBackIosIcon
          sx={{
            cursor: "pointer",
            marginBottom: 5,
            color: text2,
            fontSize: "24px",
          }}
          component={"svg"}
          onClick={() => {
            setState({
              status: "inputting",
              input: "",
            });
          }}
        />
        <Box
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <DoneIcon
            sx={{
              fontSize: "64px",
              color: green,
            }}
          />
          <Typography
            fontSize={"16px"}
            color={green}
            marginBottom={2}
            marginTop={3}
          >
            Transaction Submitted
          </Typography>
        </Box>

        <Typography color={text2} fontSize={"14px"}>
          Type
        </Typography>
        <Typography
          marginBottom={3}
          color={white}
          marginTop={0.25}
          fontSize={"14px"}
        >
          Transaction
        </Typography>
        {/* <Typography color={text2} fontSize={"14px"}>
          From
        </Typography>
        <Typography
          marginBottom={3}
          color={white}
          marginTop={0.25}
          fontSize={"14px"}
        >
          {state.from}
          
        </Typography>
        <Typography color={text2} fontSize={"14px"}>
          To
        </Typography> */}
        {/* <Typography
          marginBottom={3}
          color={white}
          marginTop={0.25}
          fontSize={"14px"}
        >
          {state.to}
        </Typography> */}
        <Typography color={text2} fontSize={"14px"}>
          Data
        </Typography>
        <Typography
          marginBottom={3}
          color={white}
          marginTop={0.25}
          fontSize={"14px"}
          sx={{
            wordBreak: "break-all",
            wordWrap: "break-word",
          }}
        >
          {decompressCallData(state.data)}
        </Typography>
        {state.value && (
          <>
            <Typography color={text2} fontSize={"14px"}>
              Value
            </Typography>
            <Typography
              marginBottom={3}
              color={white}
              marginTop={0.25}
              fontSize={"14px"}
            >
              {state.value}
            </Typography>
          </>
        )}
        <Typography
          marginBottom={3}
          color={white}
          marginTop={0.25}
          fontSize={"14px"}
          display={'flex'}
          alignItems={'center'}
        >
          TxHash:{" "}
          <Typography
            color={"#4169FF"}
            sx={{ cursor: "pointer", marginLeft: 0.5 }}
            component={"span"}
            onClick={() => {
              window.open(
                EXPLORER_MAP.get(chainId!) + "/tx/" + state.hash,
                "_blank"
              );
            }}
          >
            {state.hash}
          </Typography>
        </Typography>

        <Typography
          color={white}
          marginTop={0.25}
          fontSize={"14px"}
          display={"flex"}
          alignItems={"center"}
        >
          Network Fee:{" "}
          {state.fee ? (
            state.fee + "ETH"
          ) : (
            <CircularProgress sx={{ marginX: 1 }} size={14} />
          )}
        </Typography>
      </Box>
    ) : (
      <Box
        padding={5}
        bgcolor={darkBg2}
        marginTop={7}
        display={"flex"}
        flexDirection={"column"}
        width={"800px"}
        maxHeight={"80vh"}
        height={"500px"}
        overflow={"scroll"}
      >
        <ArrowBackIosIcon
          sx={{
            cursor: "pointer",
            marginBottom: 5,
            color: text2,
            fontSize: "24px",
          }}
          component={"svg"}
          onClick={() => {
            setState({
              status: "inputting",
              input: state.input,
            });
          }}
        />
        <Typography color={text2} fontSize={"14px"}>
          Type
        </Typography>
        <Typography
          marginBottom={3}
          color={white}
          marginTop={0.25}
          fontSize={"14px"}
        >
          Transaction
        </Typography>
        {/* <Typography color={text2} fontSize={"14px"}>
          From
        </Typography>
        <Typography
          marginBottom={3}
          color={white}
          marginTop={0.25}
          fontSize={"14px"}
        >
          {state.from}
        </Typography>
        <Typography color={text2} fontSize={"14px"}>
          To
        </Typography> */}
        {/* <Typography
          marginBottom={3}
          color={white}
          marginTop={0.25}
          fontSize={"14px"}
        >
          {state.to}
        </Typography> */}
        <Typography color={text2} fontSize={"14px"}>
          Data
        </Typography>
        <Typography
          marginBottom={3}
          color={white}
          marginTop={0.25}
          fontSize={"14px"}
          sx={{
            wordBreak: "break-all",
            wordWrap: "break-word",
          }}
        >
          {decompressCallData(state.data)}
        </Typography>
        {state.value && (
          <>
            <Typography color={text2} fontSize={"14px"}>
              Value
            </Typography>
            <Typography color={white} marginTop={0.25} fontSize={"14px"}>
              {state.value}
            </Typography>
          </>
        )}

        <MyButton
          sx={{ marginTop: 5, height: "48px" }}
          fullWidth
          onClick={async () => {
            const _json = JSON.parse(state.input);
            const pro = new providers.Web3Provider(walletProvider!);
            const signer = pro.getSigner();

            const json = {
              ..._json,
              data: decompressCallData(_json.data),
            };
            const tx = await signer
              .sendTransaction({
                to: json.to,
                data: json.data,
                value: json.value,
              })
              .catch((e) => {
                if (e.reason === "user rejected transaction") {
                  showError("User rejected transaction");
                } else if (checkIsError(e, "INVALID_NONCE")) {
                  showError("Nonce is invalid or used");
                } else {
                  showError("An error occurred.");
                }
                return undefined;
              });
            if (!tx) return;
            setState({
              status: "sent",
              from: state.from,
              to: state.to,
              data: state.data,
              value: state.value,
              hash: tx.hash,
            });
            const receipt = await tx.wait();
            const gasUsed = receipt.gasUsed;
            const gasPrice = receipt.effectiveGasPrice;
            const feeInWei = gasUsed.mul(gasPrice!);

            const feeInEth = utils.formatEther(feeInWei);
            setState({
              status: "sent",
              from: state.from,
              to: state.to,
              data: state.data,
              value: state.value,
              fee: feeInEth,
              hash: tx.hash,
            });
          }}
        >
          Confirm
        </MyButton>
        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
          <Typography
            sx={{ marginTop: 2, cursor: "pointer", color: text2 }}
            component={"span"}
            onClick={() => {
              setState({
                status: "inputting",
                input: state.input,
              });
            }}
          >
            Cancel
          </Typography>
        </Box>
      </Box>
    )
  ) : (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Typography color={white} marginTop={10} fontSize={"24px"}>
        Please connect an EOA wallet to continue the transaction
      </Typography>
      <MyButton
        sx={{ marginTop: 6, width: "300px" }}
        onClick={() => {
          open();
        }}
      >
        Connect Wallet
      </MyButton>
    </Box>
  );
};

function App() {
  const [state, setState] = useState({
    errorModal: {
      errorText: "",
      open: false,
    },
  });
  const showError = (errorText: string) => {
    setState({
      ...state,
      errorModal: {
        errorText: errorText,
        open: true,
      },
    });
  };
  return (
    <Box
      bgcolor={darkBg}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      height={"100vh"}
    >
      <Header />
      <Content showError={showError} />
      <ErrorModal
        errorText={state.errorModal.errorText}
        open={state.errorModal.open}
        onClose={() => {
          setState({
            ...state,
            errorModal: {
              ...state.errorModal,
              open: false,
            },
          });
        }}
      />
    </Box>
  );
}

export default App;
