import {
  BigNumber,
  BigNumberish,
  Contract,
  ContractReceipt,
  PopulatedTransaction,
  Signer,
  VoidSigner,
  Wallet,
  constants,
  utils,
} from "ethers";
import { UserOperation } from "../types";
import {
  arrayify,
  defaultAbiCoder,
  keccak256,
  recoverAddress,
  verifyMessage,
} from "ethers/lib/utils";
import { create2ABI, entrypointABI, erc20ABI, smartWalletABI } from "../abis";
import { getCreate2Address, getEntrypoint } from "./api";

const DefaultsForUserOp: UserOperation = {
  sender: constants.AddressZero,
  nonce: 0,
  initCode: "0x",
  callData: "0x",
  callGasLimit: 0,
  verificationGasLimit: 200000, // default verification gas. will add create2 cost (3200+200*length) if initCode exists
  preVerificationGas: 21000, // should also cover calldata cost.
  maxFeePerGas: 0,
  maxPriorityFeePerGas: 1e9,
  paymasterAndData: "0x",
  signature: "0x",
};

function getUserOpHash(
  op: UserOperation,
  entryPoint: string,
  chainId: number
): string {
  const userOpHash = keccak256(packUserOp(op, true));
  const enc = defaultAbiCoder.encode(
    ["bytes32", "address", "uint256"],
    [userOpHash, entryPoint, chainId]
  );
  return keccak256(enc);
}

function fillUserOpDefaults(
  op: Partial<UserOperation>,
  defaults = DefaultsForUserOp
): UserOperation {
  const partial: any = { ...op };
  // we want "item:undefined" to be used from defaults, and not override defaults, so we must explicitly
  // remove those so "merge" will succeed.
  for (const key in partial) {
    if (partial[key] == null) {
      // eslint-disable-next-line
      delete partial[key];
    }
  }
  const filled = { ...defaults, ...partial };
  return filled;
}

function packUserOp(op: UserOperation, forSignature = true): string {
  if (forSignature) {
    return defaultAbiCoder.encode(
      [
        "address",
        "uint256",
        "bytes32",
        "bytes32",
        "uint256",
        "uint256",
        "uint256",
        "uint256",
        "uint256",
        "bytes32",
      ],
      [
        op.sender,
        op.nonce,
        keccak256(op.initCode),
        keccak256(op.callData),
        op.callGasLimit,
        op.verificationGasLimit,
        op.preVerificationGas,
        op.maxFeePerGas,
        op.maxPriorityFeePerGas,
        keccak256(op.paymasterAndData),
      ]
    );
  } else {
    // for the purpose of calculating gas cost encode also signature (and no keccak of bytes)
    return defaultAbiCoder.encode(
      [
        "address",
        "uint256",
        "bytes",
        "bytes",
        "uint256",
        "uint256",
        "uint256",
        "uint256",
        "uint256",
        "bytes",
        "bytes",
      ],
      [
        op.sender,
        op.nonce,
        op.initCode,
        op.callData,
        op.callGasLimit,
        op.verificationGasLimit,
        op.preVerificationGas,
        op.maxFeePerGas,
        op.maxPriorityFeePerGas,
        op.paymasterAndData,
        op.signature,
      ]
    );
  }
}

function callDataCost(data: string): number {
  return utils
    .arrayify(data)
    .map((x) => (x === 0 ? 4 : 16))
    .reduce((sum, x) => sum + x);
}

async function fillUserOp(
  op: Partial<UserOperation>,
  walletFactoryAddress: string,
  entryPoint?: Contract
): Promise<UserOperation> {
  const op1 = { ...op };
  const provider = entryPoint?.provider;
  if (op.initCode != null) {
    const initAddr = utils.hexDataSlice(op1.initCode!, 0, 20);
    const initCallData = utils.hexDataSlice(op1.initCode!, 20);
    if (op1.nonce == null) op1.nonce = 0;
    if (op1.sender == null) {
      // hack: if the init contract is our known deployer, then we know what the address would be, without a view call
      if (initAddr.toLowerCase() === walletFactoryAddress.toLowerCase()) {
        const ctr = utils.hexDataSlice(initCallData, 32);
        const salt = utils.hexDataSlice(initCallData, 0, 32);
        op1.sender = utils.getCreate2Address(walletFactoryAddress, salt, ctr);
      } else {
        if (provider == null) {
          throw new Error("no entrypoint/provider");
        }
        op1.sender = await entryPoint!.callStatic
          .getSenderAddress(op1.initCode!)
          .catch((e) => e.errorArgs.sender);
      }
    }
    if (op1.verificationGasLimit == null) {
      if (provider == null) throw new Error("no entrypoint/provider");
      const initEstimate = await provider.estimateGas({
        from: entryPoint?.address,
        to: initAddr,
        data: initCallData,
        gasLimit: 10e6,
      });
      op1.verificationGasLimit = BigNumber.from(
        DefaultsForUserOp.verificationGasLimit
      ).add(initEstimate);
    }
  }
  if (op1.nonce == null) {
    if (provider == null) {
      throw new Error("must have entryPoint to autofill nonce");
    }
    const c = new Contract(
      op.sender!,
      ["function getNonce() view returns(uint256)"],
      provider
    );
    op1.nonce = await c.getNonce();
  }
  if (op1.callGasLimit == null && op.callData != null) {
    if (provider == null) {
      throw new Error("must have entryPoint for callGasLimit estimate");
    }
    const gasEtimated = await provider.estimateGas({
      from: entryPoint?.address,
      to: op1.sender,
      data: op1.callData,
    });

    // estimateGas assumes direct call from entryPoint. add wrapper cost.
    op1.callGasLimit = gasEtimated.add(55000);
  }
  if (op1.maxFeePerGas == null) {
    if (provider == null) {
      throw new Error("must have entryPoint to autofill maxFeePerGas");
    }
    const block = await provider.getBlock("latest");
    op1.maxFeePerGas = block.baseFeePerGas!.add(
      op1.maxPriorityFeePerGas ?? DefaultsForUserOp.maxPriorityFeePerGas
    );
  }
  // TODO: this is exactly what fillUserOp below should do - but it doesn't.
  // adding this manually
  if (op1.maxPriorityFeePerGas == null) {
    op1.maxPriorityFeePerGas = DefaultsForUserOp.maxPriorityFeePerGas;
  }
  const op2 = fillUserOpDefaults(op1);

  if (BigNumber.from(op2.preVerificationGas).eq(0)) {
    // TODO: we don't add overhead, which is ~21000 for a single TX, but much lower in a batch.
    op2.preVerificationGas = callDataCost(packUserOp(op2, false));
  }
  return op2;
}

async function fillAndSign(
  op: Partial<UserOperation>,
  signer: Wallet | Signer,
  walletFactoryAddress: string,
  entryPoint?: Contract
): Promise<UserOperation> {
  const provider = entryPoint?.provider;
  const op2 = await fillUserOp(op, walletFactoryAddress, entryPoint);
  const chainId = await provider!.getNetwork().then((net) => net.chainId);
  const message = arrayify(getUserOpHash(op2, entryPoint!.address, chainId));
  const sig = await signer.signMessage(message);
  const address = verifyMessage(message, sig);
  console.log("address", address);

  return {
    ...op2,
    signature: sig,
  };
}

async function getSignedUserOp(
  tx: PopulatedTransaction,
  nonce: BigNumberish,
  smartWallet: Contract,
  smartWalletOwner: Signer,
  create2: Contract,
  entrypoint: Contract
): Promise<UserOperation> {
  const partialUserOp: Partial<UserOperation> = {
    sender: smartWallet.address,
    nonce,
    callData: tx.data,
    callGasLimit: "526880",
  };
  const signedUserOp = await fillAndSign(
    partialUserOp,
    smartWalletOwner,
    create2.address,
    entrypoint
  );
  return signedUserOp;
}

export const signTransferToken = async (args: {
  network: string;
  to: string;
  smartWalletAddr: string;
  amount: BigNumber;
  tokenAddress: string;
  signer: Signer;
}) => {
  const smartWallet = new Contract(
    args.smartWalletAddr, // todo
    smartWalletABI
  ).connect(args.signer.provider!);
  const token = new Contract(args.tokenAddress, erc20ABI).connect(
    args.signer.provider!
  );
  const populated =
    // await token.populateTransaction.transfer(args.to, args.amount)
    // const transferToken =
    await smartWallet.populateTransaction.transferToken(
      args.tokenAddress,
      args.to,
      args.amount,
      "0x",
      false
    );
  const c = new Contract(
    args.smartWalletAddr!,
    ["function getNonce() view returns(uint256)"],
    args.signer.provider!
  );
  // op1.nonce = await c.getNonce()
  const nonce = await c.getNonce();

  const ep = await getEntrypoint();
  const create2Address = await getCreate2Address();
  const entrypoint = new Contract(ep, entrypointABI).connect(
    args.signer.provider!
  );
  const create2 = new Contract(create2Address, create2ABI).connect(
    args.signer.provider!
  );
  return getSignedUserOp(
    populated,
    nonce,
    smartWallet,
    args.signer,
    create2,
    entrypoint
  );
};

type SendUserOp = (userOp: UserOperation) => Promise<ContractReceipt>;

function localUserOpSender(
  entryPoint: Contract,
  signer: Signer,
  beneficiary?: string
): SendUserOp {
  // const entryPoint = EntryPoint__factory.connect(
  //   entryPointAddress,
  //   signer
  // )

  return async function (userOp) {
    const ret = await entryPoint.handleOps(
      [userOp],
      beneficiary ?? (await signer.getAddress()),
      {
        maxPriorityFeePerGas: userOp.maxPriorityFeePerGas,
        maxFeePerGas: userOp.maxFeePerGas,
      }
    );

    const recipt = await ret.wait();
    return recipt;
  };
}

export const sendUserOp = async (
  network: string,
  userOp: UserOperation,
  signer: Signer
) => {
  const ep = await getEntrypoint();
  const entrypoint = new Contract(ep, entrypointABI, signer);
  const sendUserOpFn = localUserOpSender(entrypoint, signer);
  return sendUserOpFn(userOp);
};

export function checkIsError(
  error: Error,
  expectedRevertMessage?: string
) {
  // try {
  //   await promise;
  // } catch (error) {
  if (expectedRevertMessage) {
    return error.message.search(expectedRevertMessage) >= 0;

    // assert(
    //   message,
    //   "Expected throw with message " +
    //     expectedRevertMessage +
    //     ", got '" +
    //     error +
    //     "' instead"
    // );
  } else {
    const revert = error.message.search("revert") >= 0;
    const invalidOpcode = error.message.search("invalid opcode") >= 0;
    return revert || invalidOpcode;
  }
  // }
}
